import React, { useEffect, useState } from 'react';

const RetroClock = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
        return time.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    return (
        <div className="flex items-center justify-center">
            <div className="retro-clock text-red-600 text-8xl font-mono">
                {formatTime(time)}
            </div>
        </div>
    );
};

export default RetroClock;
