import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import reportWebVitals from './reportWebVitals';
import JsonEditor from './tools/pages/jsoneditor';
import Embeds from './tools/pages/embeds';
import { Buffer } from 'buffer';
import stream from 'stream-browserify';
import process from 'process';

window.Buffer = Buffer;
window.stream = stream;
window.process = process;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path='/jsoneditor' element={<Layout />}>
          <Route index element={<JsonEditor />} />
        </Route>
        <Route path='/embeds/:website' element={<Layout />}>
          <Route index element={<Embeds />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
