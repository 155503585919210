import React from 'react';
import RetroClock from '../components/retroClock';

const Home = () => {
    const wallpaperUrl = [
        {
            "class": "bg-wallpaper1",
            "url": "https://unsplash.com/photos/body-of-water-surrounded-by-pine-trees-during-daytime-ESkw2ayO2As",
            "selected": false
        },
        {
            "class": "bg-wallpaper2",
            "url": "https://unsplash.com/photos/low-angle-photography-of-trees-at-daytime-4rDCa5hBlCs",
            "selected": false
        },
        {
            "class": "bg-wallpaper3",
            "url": "https://unsplash.com/photos/a-close-up-of-a-leaf-with-drops-of-water-on-it-4WhzY_EfSw8",
            "selected": false
        },
        {
            "class": "bg-wallpaper4",
            "url": "https://unsplash.com/photos/a-city-street-at-night-in-the-rain-m36bzA0Oq4g",
            "selected": true
        },
    ]

    var selectedWallPaperClass = wallpaperUrl.find(wallpaper => wallpaper.selected).class;
    var selectedWallPaperUrl = wallpaperUrl.find(wallpaper => wallpaper.selected).url;

    return (
        <div className={`w-screen h-screen flex items-center justify-center scrollbar-hide bg-cover bg-no-repeat ${selectedWallPaperClass}`}>
            <RetroClock />
            <div className="absolute bottom-0 left-0 p-4 text-neutral-300">
                <a href={selectedWallPaperUrl} target="_blank" rel="noopener noreferrer" className='text-sm bg-neutral-950 text-neutral-50'>Author</a>
            </div>
        </div>

    );
};

export default Home;