import React, { useState, useEffect, useCallback, useRef } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-xml';
import { parse, getLocation } from 'jsonc-parser';
import { json2xml, xml2json } from 'xml-js';
import { faClose, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Message from '../../components/message';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import Cookies from 'js-cookie';

const JsonEditor = () => {
    const [jsonValue, setJsonValue] = useState('{"test":"dummy"}');
    const [error, setError] = useState(null);
    const [annotations, setAnnotations] = useState([]); // Manage annotations without markers
    const [, setEditor] = useState(null); // To reference the editor instance
    const [replaceSource, setReplaceSource] = useState('');
    const [replaceTarget, setReplaceTarget] = useState('');
    const [xmlValue, setXmlValue] = useState('');
    const [cSharpValue, setCSharpValue] = useState('');
    const [convertDirection, setConvertDirection] = useState('jsontoxml');
    const hasSetCookie = useRef(false); // Track if the cookie has already been set

    const validateJson = useCallback((value) => {
        // Check for invalid backslashes before quotes
        const invalidBackslashMatch = value.match(/(.*?)(\\")/);
        if (invalidBackslashMatch) {
            const lineNumber = value.substr(0, invalidBackslashMatch.index).split("\n").length - 1; // Calculate line number
            setError("Invalid JSON: Backslashes before quotes are not allowed. Please remove them.");

            // Set an annotation for the invalid backslash
            const newAnnotations = [{
                row: lineNumber,
                column: invalidBackslashMatch.index - invalidBackslashMatch[1].length,
                text: "Invalid backslash before quote", // Custom error message
                type: "error" // This sets the annotation type to an error
            }];
            setAnnotations(newAnnotations); // Add annotations for the error

            return; // Exit early if invalid
        }

        const errors = [];
        parse(value, errors);  // Validate the current value

        if (errors.length > 0) {
            // Handle the first error
            const error = errors[0];
            try {
                // Try to get the location of the error
                const location = getLocation(value, error.offset);

                if (location && !isNaN(location.line) && !isNaN(location.column)) {
                    const errorMessage = `Error at line ${location.line + 1}, column ${location.column + 1}: ${error.error}`;
                    setError(errorMessage);

                    // Set an annotation to highlight the error
                    const newAnnotations = [{
                        row: location.line, // AceEditor uses 0-indexed rows
                        column: location.column,
                        text: error.error,  // Error message to show
                        type: "error" // Can be "error", "warning", or "info"
                    }];
                    setAnnotations(newAnnotations); // Set the annotations for the editor
                } else {
                    // Fallback if location can't be determined
                    setError(`Error at unknown location: ${error.error} (Offset: ${error.offset})`);
                    setAnnotations([]); // Clear annotations
                }
            } catch (e) {
                // Fallback if getLocation throws an error
                setError(`Error: ${error.error}`);
                setAnnotations([]); // Clear annotations
            }
        } else {
            setError(null);  // Clear the error if no validation issues
            setAnnotations([]); // Clear annotations
        }
        Cookies.set('cookiejson', value, { expires: 7 }); // expires in 7 days
    }, []);

    const prettifyJson = () => {
        try {
            const prettyJson = JSON.stringify(JSON.parse(jsonValue), null, 2);
            setJsonValue(prettyJson);
            setError(null);
            setAnnotations([]); // Clear annotations
        } catch (e) {
            setError(e.message);
        }
    };

    const minifyJson = () => {
        try {
            const minifiedJson = JSON.stringify(JSON.parse(jsonValue));
            setJsonValue(minifiedJson);
            setError(null);
            setAnnotations([]); // Clear annotations
        } catch (e) {
            setError(e.message);
        }
    };

    const replaceJsonValue = () => {
        try {
            // Escape special characters in the replaceSource string for regex safety
            const safeReplaceSource = replaceSource.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            // Perform the replacement
            const newJsonValue = jsonValue.replace(new RegExp(safeReplaceSource, 'g'), replaceTarget);

            // Validate the new JSON after replacement
            JSON.parse(newJsonValue); // This will throw an error if the new JSON is invalid

            setJsonValue(newJsonValue); // Update the state if valid
            setError(null); // Clear any previous errors
            setAnnotations([]); // Clear annotations if valid
        } catch (e) {
            setError(`Invalid JSON after replacement: ${e.message}`);
        }
    };

    useEffect(() => {
        if (!hasSetCookie.current) {
            setJsonValue(Cookies.get('cookiejson'));
            hasSetCookie.current = true;
        }
        validateJson(jsonValue);
    }, [jsonValue, validateJson]);

    // Function to simplify the JSON structure
    const simplifyJson = (jsonObj) => {
        const simplified = {};
        for (let key in jsonObj) {
            if (jsonObj[key]._text) {
                simplified[key] = jsonObj[key]._text;
            } else {
                simplified[key] = simplifyJson(jsonObj[key]);
            }
        }
        return simplified;
    };
    const parseObject = (obj, className, definedClasses) => {
        const sanitizedClassName = toPascalCase(className); // PascalCase for class names
        if (definedClasses.has(sanitizedClassName)) return '';

        let csharpCode = `public class ${sanitizedClassName} {\n`;

        for (let key in obj) {
            const originalKey = key;
            const sanitizedKey = toPascalCase(key); // camelCase for property names
            const propertyType = typeof obj[key];

            if (Array.isArray(obj[key])) {
                const itemClass = toPascalCase(key); // PascalCase for array item class names
                csharpCode += `    [JsonProperty("${originalKey}")]\n`;
                csharpCode += `    public List<${itemClass}> ${sanitizedKey} { get; set; }\n`;

                if (typeof obj[key][0] === 'object' && obj[key][0] !== null) {
                    parseObject(obj[key][0], itemClass, definedClasses);
                }
            } else if (propertyType === 'object' && obj[key] !== null) {
                const nestedClass = toPascalCase(key);
                csharpCode += `    [JsonProperty("${originalKey}")]\n`;
                csharpCode += `    public ${nestedClass} ${sanitizedKey} { get; set; }\n`;

                parseObject(obj[key], nestedClass, definedClasses);
            } else {
                csharpCode += `    [JsonProperty("${originalKey}")]\n`;
                csharpCode += `    public ${getCSharpType(obj[key])} ${sanitizedKey} { get; set; }\n`;
            }
        }

        csharpCode += `}\n\n`;
        definedClasses.set(sanitizedClassName, csharpCode);
    };

    const generateCSharpClasses = (jsonObject) => {
        const definedClasses = new Map();
        parseObject(jsonObject, 'RootObject', definedClasses);

        return `using Newtonsoft.Json;\n\n${Array.from(definedClasses.values()).join('\n')}`;
    };

    // Utility functions
    const getCSharpType = (value) => {
        switch (typeof value) {
            case 'number':
                return Number.isInteger(value) ? 'int' : 'double';
            case 'string':
                return 'string';
            case 'boolean':
                return 'bool';
            default:
                return 'object';
        }
    };

    // Converts a name to PascalCase (DataObject)
    const toPascalCase = (name) => {

        const pattern = /^[A-Z][A-Za-z]*$/;
        if (pattern.test(name)) {
            return name;
        }

        if (isCamelCase(name))
            return name.charAt(0).toUpperCase() + name.slice(1);

        return name.toLowerCase()
            .replace(new RegExp(/[-_]+/, 'g'), ' ') // Replace hyphens and underscores with spaces
            .replace(new RegExp(/[^\w\s]/, 'g'), '') // Remove non-word characters
            .replace(
                new RegExp(/\b\w/g), // Match the start of each word
                s => s.toUpperCase() // Capitalize each matched character
            )
            .replace(/\s+/g, ''); // Remove spaces between words
    };

    const isCamelCase = (name) => {
        const camelCasePattern = /^[a-z]+([A-Z][a-z0-9]*)*$/;
        return camelCasePattern.test(name);
    };

    const convert = () => {
        if (convertDirection === "jsontoxml") {
            try {
                const jsonObject = JSON.parse(jsonValue); // Parse JSON string to object
                if (typeof jsonObject !== 'object') {
                    throw new Error("Invalid JSON format. Must be an object.");
                }
                const xml = json2xml(jsonObject, { compact: true, spaces: 4 }); // Convert JSON object to XML
                setXmlValue(xml); // Set XML content in the state
            } catch (error) {
                console.error("Invalid JSON or XML conversion error:", error);
                setError("Invalid JSON. Please fix it before converting.");
            }
        }

        if (convertDirection === 'xmltojson') {
            try {
                // Convert XML to JSON with xml-js
                const json = JSON.parse(xml2json(xmlValue, { compact: true }));
                // Simplify the resulting JSON
                const simplifiedJson = simplifyJson(json);
                var stringJson = JSON.stringify(simplifiedJson, null, 2);
                setJsonValue(stringJson);
            } catch (error) {
                console.error("Conversion error:", error);
                throw new Error("Invalid XML. Please check the format.");
            }
        }
        if (convertDirection === 'jsontocsharp') {
            try {
                const obj = JSON.parse(jsonValue);
                setCSharpValue(generateCSharpClasses(obj, 'RootObject'));

            } catch (error) {
                console.error("Invalid JSON or XML conversion error:", error);
                setError("Invalid JSON. Please fix it before converting.");
            }
        }
    };

    const copyClipboardJson = () => {
        navigator.clipboard.writeText(jsonValue);
        Message.ShowSuccess("Copied the clipboard.");
    };

    const downloadJson = () => {
        const element = document.createElement("a");
        const file = new Blob([jsonValue], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "swissknife.json";
        document.body.appendChild(element); // Required for this to work in Firefox
        element.click();
    }

    const copyClipboardXml = () => {
        navigator.clipboard.writeText(xmlValue);
        Message.ShowSuccess("Copied the clipboard.");
    };

    const downloadXml = () => {
        const element = document.createElement("a");
        const file = new Blob([xmlValue], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "swissknife.xml";
        document.body.appendChild(element); // Required for this to work in Firefox
        element.click();
    }

    const closeXmlEditor = () => {
        setXmlValue('');
    }

    const copyClipboardCSharp = () => {
        navigator.clipboard.writeText(cSharpValue);
        Message.ShowSuccess("Copied the clipboard.");
    };

    const downloadCSharp = () => {
        const element = document.createElement("a");
        const file = new Blob([cSharpValue], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "swissknife.cs";
        document.body.appendChild(element); // Required for this to work in Firefox
        element.click();
    }

    const closeCSharEditor = () => {
        setCSharpValue('');
    }

    return (
        <div>
            <ToastContainer />
            <div className='flex flex-row gap-2'>
                <div className={`${((xmlValue && xmlValue.trim() !== "") || (cSharpValue && cSharpValue.trim() !== "")) ? 'basis-2/4' : 'w-screen'}`}>
                    <div className="bg-ace-editor-gutter-background flex flex-row-reverse gap-3 pr-2 pl-3 pt-1 pb-1">
                        <FontAwesomeIcon icon={faCopy} onClick={copyClipboardJson} className='cursor-pointer' />
                        <FontAwesomeIcon icon={faDownload} onClick={downloadJson} className='cursor-pointer' />
                    </div>
                    <AceEditor
                        mode="json"
                        theme="monokai"
                        name="jsonEditor"
                        onChange={setJsonValue}
                        value={jsonValue}
                        width="100%"
                        height="calc(100vh - 110px)" // Full height minus some space for the header and buttons
                        fontSize={12}
                        setOptions={{
                            useWorker: false, // Disable web workers to avoid parsing issues
                            cursorStyle: "smooth",
                            mergeUndoDeltas: true,
                            behavioursEnabled: true,
                            wrapBehavioursEnabled: true,
                            /** this is needed if editor is inside scrollable page */
                            autoScrollEditorIntoView: false,
                            hScrollBarAlwaysVisible: false,
                            vScrollBarAlwaysVisible: false,
                            highlightGutterLine: true,
                            animatedScroll: true,
                            showInvisibles: false,
                            showPrintMargin: false,
                            fadeFoldWidgets: true,
                            showFoldWidgets: true,
                            showLineNumbers: true,
                            showGutter: true,
                            displayIndentGuides: false,
                            useSoftTabs: true,
                            wrap: true,
                            foldStyle: "markbegin",
                            enableMultiselect: true,
                        }}
                        annotations={annotations} // Pass annotations to AceEditor
                        onLoad={(editorInstance) => setEditor(editorInstance)} // Get editor instance
                    />
                </div>
                {(() => {
                    try {
                        if (xmlValue && xmlValue.trim() !== "") {
                            // If xmlValue is not empty and has valid content, render the editor
                            return (
                                <div className='basis-2/4'>
                                    <div className="bg-ace-editor-gutter-background flex flex-row-reverse gap-3 pr-2 pl-3 pt-1 pb-1">
                                        <FontAwesomeIcon icon={faClose} onClick={closeXmlEditor} className='cursor-pointer' />
                                        <FontAwesomeIcon icon={faCopy} onClick={copyClipboardXml} className='cursor-pointer' />
                                        <FontAwesomeIcon icon={faDownload} onClick={downloadXml} className='cursor-pointer' />
                                    </div>
                                    <AceEditor
                                        mode="xml"
                                        theme="monokai"
                                        name="xmlEditor"
                                        value={xmlValue}
                                        onChange={setXmlValue}
                                        width="100%"
                                        height="calc(100vh - 110px)" // Full height minus some space for the header and buttons
                                        fontSize={12}
                                        setOptions={{
                                            useWorker: false, // Disable web workers to avoid parsing issues
                                            cursorStyle: "smooth",
                                            mergeUndoDeltas: true,
                                            behavioursEnabled: true,
                                            wrapBehavioursEnabled: true,
                                            /** this is needed if editor is inside scrollable page */
                                            autoScrollEditorIntoView: false,
                                            hScrollBarAlwaysVisible: false,
                                            vScrollBarAlwaysVisible: false,
                                            highlightGutterLine: true,
                                            animatedScroll: true,
                                            showInvisibles: false,
                                            showPrintMargin: false,
                                            fadeFoldWidgets: true,
                                            showFoldWidgets: true,
                                            showLineNumbers: true,
                                            showGutter: true,
                                            displayIndentGuides: false,
                                            useSoftTabs: true,
                                            wrap: true,
                                            foldStyle: "markbegin",
                                            enableMultiselect: true,
                                        }}
                                    />
                                </div>
                            );
                        } else {
                            // If xmlValue is empty, don't render anything
                            return null;
                        }
                    } catch (e) {
                        console.error("Invalid XML:", e);
                        // If there's any error during validation, do not render the editor
                        return null;
                    }
                })()}
                {(() => {
                    try {
                        if (cSharpValue && cSharpValue.trim() !== "") {
                            // If xmlValue is not empty and has valid content, render the editor
                            return (
                                <div className='basis-2/4'>
                                    <div className="bg-ace-editor-gutter-background flex flex-row-reverse gap-3 pr-2 pl-3 pt-1 pb-1">
                                        <FontAwesomeIcon icon={faClose} onClick={closeCSharEditor} className='cursor-pointer' />
                                        <FontAwesomeIcon icon={faCopy} onClick={copyClipboardCSharp} className='cursor-pointer' />
                                        <FontAwesomeIcon icon={faDownload} onClick={downloadCSharp} className='cursor-pointer' />
                                    </div>
                                    <AceEditor
                                        mode="xml"
                                        theme="monokai"
                                        name="xmlEditor"
                                        value={cSharpValue}
                                        onChange={setCSharpValue}
                                        width="100%"
                                        height="calc(100vh - 110px)" // Full height minus some space for the header and buttons
                                        fontSize={12}
                                        setOptions={{
                                            useWorker: false, // Disable web workers to avoid parsing issues
                                            cursorStyle: "smooth",
                                            mergeUndoDeltas: true,
                                            behavioursEnabled: true,
                                            wrapBehavioursEnabled: true,
                                            /** this is needed if editor is inside scrollable page */
                                            autoScrollEditorIntoView: false,
                                            hScrollBarAlwaysVisible: false,
                                            vScrollBarAlwaysVisible: false,
                                            highlightGutterLine: true,
                                            animatedScroll: true,
                                            showInvisibles: false,
                                            showPrintMargin: false,
                                            fadeFoldWidgets: true,
                                            showFoldWidgets: true,
                                            showLineNumbers: true,
                                            showGutter: true,
                                            displayIndentGuides: false,
                                            useSoftTabs: true,
                                            wrap: true,
                                            foldStyle: "markbegin",
                                            enableMultiselect: true,
                                        }}
                                    />
                                </div>
                            );
                        } else {
                            // If xmlValue is empty, don't render anything
                            return null;
                        }
                    } catch (e) {
                        console.error("Invalid C#:", e);
                        // If there's any error during validation, do not render the editor
                        return null;
                    }
                })()}
            </div>
            <div className='flex pl-1 text-xs'>
                {error != null && jsonValue !== ""
                    ? <p className='text-red-500'>Error: {error}</p>
                    : jsonValue !== "" ? <p className='text-green-300'>Json is valid</p> : <span>Waiting for action...</span>
                }
            </div>
            <div className='flex flex-row gap-2 p-2'>
                <button onClick={prettifyJson} className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1'>
                    Prettify JSON
                </button>
                <button onClick={minifyJson} className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1'>
                    Minify JSON
                </button>
                {/* <button onClick={() => validateJson(jsonValue)} className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1'>
                    Validate JSON
                </button> */}
                <select onChange={e => setConvertDirection(e.target.value)
                } className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1'>
                    <option value="jsontoxml">XML</option>
                    <option value="xmltojson">Json</option>
                    <option value="jsontocsharp">C#</option>
                </select>
                <button onClick={() => convert()} className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1'>
                    Convert
                </button>

            </div>
            <div className='flex flex-row gap-2 pl-5'>
                <input type="text" className='border-2 border-gray-300 rounded-md text-neutral-900 pl-2 pr-2 text-xs' value={replaceSource} onChange={(e) => setReplaceSource(e.target.value)} placeholder="From" />
                <input type="text" className='border-2 border-gray-300 rounded-md text-neutral-900 pl-2 pr-2 text-xs' value={replaceTarget} onChange={(e) => setReplaceTarget(e.target.value)} placeholder="To" />
                <button onClick={replaceJsonValue} data-tooltip-id="replace-btn-info" data-tooltip-place="right-end" data-tooltip-content="This function only work for json editor" className='rounded-md border border-transparent text-center text-xs transition-all text-neutral-800 bg-neutral-50 hover:bg-neutral-700 hover:text-neutral-50 active:bg-neutral-50 active:text-neutral-800 p-1' >
                    Replace
                </button>
                <Tooltip id="replace-btn-info" />

            </div>
        </div>
    );
};

export default JsonEditor;
