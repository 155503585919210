import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SwissKnifeButton from "../components/swissknifeButton";
import { AnimatePresence, motion } from "framer-motion";

const Layout = () => {
    const location = useLocation();

    return (
        <>
            <div key={location.key}
                className="h-full overflow-auto overscroll-none">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={location.pathname}
                        initial={{ opacity: 0, x: 0, y: 20 }}
                        animate={{ opacity: 1, x: 0, y: 0 }}
                        exit={{ opacity: 0, x: 0, y: 20 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Outlet />
                    </motion.div>
                </AnimatePresence>
            </div>
            <SwissKnifeButton />
        </>
    )
};

export default Layout;