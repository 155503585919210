import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Music from '../tools/pages/music';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic } from '@fortawesome/free-solid-svg-icons';

const SwissKnifeButton = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuFullyVisible, setMenuIsFullyVisible] = useState(false);
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);
    const [isPlayerFullyVisible, setPlayerIsFullyVisible] = useState(false);

    const toggleMenuVisibility = () => {
        setIsMenuVisible(!isMenuVisible);
        setTimeout(() => setMenuIsFullyVisible(!isMenuVisible), 250); // Hide after fade-out
    }

    const toggleMusicVisibility = () => {
        setIsPlayerVisible(!isPlayerVisible);
        setTimeout(() => setPlayerIsFullyVisible(!isPlayerVisible), 250);
    }

    return <>

        <div className={`fixed 
                        z-30
                         bottom-20 
                         right-5 
                        bg-swissknifebtn-light 
                        text-swissknifebtn-dark 
                        p-2 
                        rounded-lg 
                        overflow-hidden 
                        cursor-pointer
                        transition-opacity duration-500 ease-in-out
                         ${isMenuVisible ? 'opacity-100' : 'opacity-0'} 
                         ${isMenuFullyVisible ? 'block' : 'hidden'}`}>
            <nav className='p-1'>
                <ul>
                    <li className='pb-1'>
                        <Link to="/" onClick={toggleMenuVisibility} >Home</Link>
                    </li>
                    <li className='pb-1'>
                        <Link to="/jsoneditor" onClick={toggleMenuVisibility}>JsonEditor</Link>
                    </li>
                    <li className='pb-1'>
                        <Link to="/embeds/excalidraw" onClick={toggleMenuVisibility}>Excalidraw</Link>
                    </li>
                </ul>
            </nav>
        </div>

        <div className={`fixed 
                         z-30
                         bottom-16 
                         right-20
                        bg-neutral-500/50
                        text-neutral-50
                        backdrop-blur-sm
                        drop-shadow-lg
                        p-2 
                        rounded-lg 
                        overflow-hidden 
                        transition-opacity duration-500 ease-in-out
                         ${isPlayerVisible ? 'opacity-100' : 'opacity-0'} 
                         ${isPlayerFullyVisible ? 'block' : 'hidden'}`}>
            <div className='w-full flex flex-row-reverse cursor-pointer' onClick={toggleMusicVisibility}>
                <span className='rounded-xl pr-1.5 pl-1.5 bg-red-700 text-neutral-100'>x</span>
            </div>
            <Music />
        </div>

        <FontAwesomeIcon icon={faMusic} className='cursor-pointer fixed bottom-24 right-20 text-stone-300 z-20 size-6 swing drop-shadow-md' onClick={toggleMusicVisibility} />
        {<div className="fixed z-10 bottom-3 right-0 size-24" onClick={toggleMenuVisibility}>
            <div className="bg-swiss-knife-button bg-cover bg-center h-24 w-full cursor-pointer">
            </div>
        </div>}
    </>;
};

export default SwissKnifeButton;