import { useParams } from "react-router-dom";

const Embeds = () => {
    let { website } = useParams();

    const excalidraw = () => {
        return (<>
            <iframe src="https://excalidraw.com" title="excalidraw" className="w-screen h-screen" />
        </>)
    }

    switch (website) {
        case 'excalidraw':
            return excalidraw();
        default:
            break;
    }
    return (
        <>
            <h1>Empty❤️</h1>
        </>)
}

export default Embeds;