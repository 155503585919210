import { toast } from 'react-toastify';
const duration = 1000;
const position = 'top-right';

const Message = {
    ShowSuccess: (message) => {
        toast.success(message, {
            position: position,
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    ShowError: (message) => {
        toast.error(message, {
            position: position,
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    }
}
export default Message;